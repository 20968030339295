<template>
  <div class="main-content q-pa-md">
    <Mural :breadcrumbs="breadcrumbs" route="store" :query="{}">
      <DefaultBtn
        v-show="validateActionsPage('store-add-products')"
        titleButton="Novo Produto"
        icon="add"
        route="new-product"
      />
    </Mural>

    <main class="lesson-content q-my-lg">
      <div class="content-table row flex q-pt-lg">
        <div class="column col-12">
          <Table
            class="full-height"
            :list="products"
            :columns="columnsTable"
            :addEdit="validateActionsPage('store-edit-products')"
            :addRemove="validateActionsPage('store-delete-products')"
            @disabled="(item) => disabled(item)"
            @edit="(item) => edit(item)"
            ><template v-slot:body="props">
              <div>
                <q-btn
                  dense
                  round
                  color="default-pink"
                  title="Editar"
                  flat
                  @click="() => edit(props.props.row)"
                  icon="o_edit_note"
                />
                <q-btn
                  dense
                  round
                  color="default-pink"
                  title="Remover"
                  flat
                  @click="() => disabled(props.props.row)"
                  icon="o_delete_outline"
                />
              </div>
            </template>
          </Table>
        </div>
      </div>
    </main>
    <q-inner-loading
      :showing="loadingPage"
      label="Por favor aguarde..."
      label-class="text-teal"
      label-style="font-size: 1.1em"
    />
  </div>
</template>

<script>
import Mural from "@/components/Mural.vue";
import DefaultBtn from "@/components/shared/buttons/DefaultBtn.vue";
import WareHouseService from "@/services/WareHouseService";
import Table from "@/components/shared/tables/TableDynamic.vue";

import CanUserAccessClass from "@/utils/CanUserAccessClass";

import { ref, onMounted } from "vue";
import { useQuasar } from "quasar";
import { useRouter } from "vue-router";
export default {
  name: "Product",
  components: {
    Mural,
    DefaultBtn,
    Table,
  },
  setup() {
    let _wareHouseService = new WareHouseService();
    let breadcrumbs = ref(["Produtos"]);
    let products = ref([]);
    let $q = new useQuasar();
    let router = new useRouter();
    let loadingPage = ref(false);

    let canUserAccessClass = new CanUserAccessClass();

    let columnsTable = ref([
      { name: "id", align: "left", label: "ID", field: "id", sortable: false },
      {
        name: "name",
        required: true,
        label: "Nome",
        align: "left",
        field: (row) => row.name,
        sortable: true,
      },
      {
        name: "acao",
        align: "center",
        label: "Ações",
        field: "acoes",
        sortable: false,
      },
    ]);

    function validateActionsPage(routeName) {
      if (!canUserAccessClass.canUserAccess(routeName, false)) {
        return false;
      }
      return true;
    }

    async function disabled(product) {
      $q.notify({
        message: `Deseja remover esse produto ${product.name}`,
        color: "red-9",
        position: "bottom",
        actions: [
          {
            label: "Remover",
            color: "white",
            handler: async () => {
              await removeProduct(product.id);
            },
          },
        ],
      });
    }

    function edit(product) {
      router.push({ name: "new-product", query: { productId: product.id } });
    }

    async function removeProduct(productId) {
      loadingPage.value = true;
      await _wareHouseService.disbeldProduct(productId).catch((error) => {
        alertErro(error.response.data.toString());
        loadingPage.value = false;
      });

      products.value = products.value.filter((product) => {
        return product.id != productId;
      });
      loadingPage.value = false;
    }

    async function _getAllProduct() {
      loadingPage.value = true;
      let data = await _wareHouseService.getAllProducts();
      products.value = data.data;
      loadingPage.value = false;
    }

    function alertErro(message) {
      $q.notify({
        message: message,
        color: "red-9",
        position: "top",
      });
      loading.value = false;
    }
    onMounted(() => {
      _getAllProduct();
    });
    return {
      breadcrumbs,
      products,
      loadingPage,
      columnsTable,
      disabled,
      edit,
      validateActionsPage,
    };
  },
};
</script>

<style lang="scss" scoped>
.main-content {
  width: 100%;
  height: 100%;

  .lesson-content {
    width: 100%;
    height: 75vh;
    overflow-y: auto;
  }
}
.content-card {
  width: 100%;
  height: 206px;
  background: #f7f7f7;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
}

.main-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  color: #000000;
}

.content {
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 16px;
  letter-spacing: 0.07em;
  color: #9c9c9c;

  .bold_number {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    color: #000000 !important;
  }
}

.image-groups {
  border-radius: 20px;
}

.card-btn {
  box-shadow: 3px 10px 15px #00000033;
  width: 100px;
}
</style>
